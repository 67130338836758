/*
  마이렛미업 컨텐츠 페이지
*/

import React, { useCallback, useEffect, useState } from "react"
import { debounce } from "lodash"
import { useDispatch } from "react-redux"
import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import LeftSideBar from "../../components/my/LeftSideBar"
import RightSideBar from "../../components/my/RightSideBar"
import PrivateRoute from "../../components/privateRoute"
import UserContainer from "../../containers/UserContainer"
import MyContainer from "../../containers/MyContainer"
import { useMediaQuery } from "react-responsive"
import temp_banner from "../../images/temp_banner.svg"
import temp_m_banner from "../../images/temp_m_banner.svg"
import { Desktop, Mobile } from "../../components/layouts/mediaQuery"
import img_checkbox from "../../images/img_checkbox.svg"
import img_checkbox_active_black from "../../images/img_checkbox_active_black.svg"
import img_checkbox_active from "../../images/img_checkbox_active.svg"
import contents_checkbox_on from "../../images/contants_checkbox_on.svg"
import contents_checkbox_off from "../../images/contants_checkbox_off.svg"
import img_common_contents_card_right_orange from "../../images/img_common_contents_card_right_orange.svg"
import img_common_contents_card_right_green from "../../images/img_common_contents_card_right_green.svg"
import img_border_x from "../../images/img_border_x.svg"
import { data } from "dom7"
import { rnMessageListener } from "../../utils/util"
import { navigate } from "gatsby-link"
import { setBrainwavePopup } from "../../store/modules/popup"
//컨텐츠

const COUNT = 15
const Contents = props => {
  const {
    getContentsBrandName,
    getContentsRecommendation,
    getContentsRecommendationAdd,
    getContentsMy,
    getContentsMyAdd,
    setMyContentsRecommendation,
    setMyContentsRecommendationTotal,
    setMyContents,
    setMyContentstotal,
    contentsBarnd,
    contents,
    contentsTotal,
    contentsRecommendation,
    contentsRecommendationTotal,
    postContentsMy,
    delContentsMy,
  } = props
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ maxWidth: 576 })
  const [checkList, setCheckList] = useState([])
  const [page, setPage] = useState(0)
  const [tab, setTab] = useState(0)
  const [brandName, setBrandName] = useState("")
  const [openMessage, setOpenMessage] = useState({ open: false, data: {}, type: 0 })
  const cardColor = {
    "취업/자격증": 5,
    중학생: 1,
    고등학생: 2,
    대학생: 3,
    대입수험생: 4,
    공무원: 6,
    기타: 8,
    직장인: 7,
  }

  const clickBrandFn = async () => {
    await getContentsRecommendation({ contentSeq: "", brandName: brandName == "전체" ? "" : brandName })
    setPage(0)
  }

  const getDate = async () => {
    await getContentsBrandName()
    if (tab == 0) {
      await getContentsMy({ contentReadSeq: "" })
    } else {
      await getContentsRecommendation({ contentSeq: "", brandName: "" })
    }
    setPage(0)
  }

  useEffect(() => {
    getDate()
    setCheckList([])
  }, [tab])

  useEffect(() => {
    clickBrandFn()
    setCheckList([])
  }, [brandName])

  // 전체 선택 버튼 핸들러
  const onClickAllCheckBox = (arr, seq) => {
    if (checkList.length === contents.length && contents.length !== 0) setCheckList([])
    else setCheckList(arr.map(i => i[seq]))
  }

  // 개별 체크 버튼 핸들러
  const onClickCheckBox = seq => {
    const temp = [...checkList]
    const findIndex = temp.findIndex(i => i === seq)

    if (findIndex === -1) {
      temp.push(seq)
      setCheckList(temp)
    } else {
      temp.splice(findIndex, 1)
      setCheckList(temp)
    }
  }

  const increasePage = debounce(async () => {
    // totalCount 조건
    if (tab == 0) {
      await getContentsMyAdd({ myContents: contents, contentReadSeq: contentReadSeq })
    } else {
      await getContentsRecommendationAdd({
        brandName: brandName,
        contents: contentsRecommendation,
        contentSeq: contentsRecommendation[contentsRecommendation.length - 1].contentSeq,
      })
    }
    if (COUNT * (page + 1) < (tab == 0 ? contentsTotal : contentsRecommendationTotal)) setPage(page + 1)
  }, 500)

  // 선택 삭제 버튼
  const onClickDeleteList = debounce(async () => {
    if (checkList.length <= 0) return false
    let result = await delContentsMy({ contentReadSeqs: checkList })
    if (result) {
      await getDate()
    }
    setCheckList([])
  }, 500)

  const messageDataFn = useCallback(e => {
    let data = JSON.parse(e.data)
    if (data.type == "BACK") navigate(-1)
  }, [])

  useEffect(() => {
    if (window.ReactNativeWebView) {
      document.addEventListener("message", messageDataFn)
    }
    return () => {
      document.removeEventListener("message", messageDataFn)
      setMyContentsRecommendation([])
      setMyContentsRecommendationTotal(0)
      setMyContents([])
      setMyContentstotal(0)
    }
  }, [])

  return (
    <PrivateRoute>
      <Layout title="내 컨텐츠">
        <SEO title="마이렛미업" />
        <div className="d-flex justify-content-center ">
          <LeftSideBar />
          <div className="container my-container">
            <div className="my-buddy-container mt-4">
              <p className="title">내 컨텐츠</p>
              <div className="my-message-top-btn-div">
                <div className="my-message-top-btn-view">
                  <button onClick={() => setTab(0)} className={tab == 0 ? "active" : ""}>
                    내 컨텐츠
                  </button>
                  <button onClick={() => setTab(1)} className={tab == 1 ? "active" : ""}>
                    추천 컨텐츠
                  </button>
                </div>
              </div>
              {tab == 1 && (
                <div className="my-contents-keyword-div">
                  <div className="my-contents-keyword-div-box">
                    {["전체", ...contentsBarnd].map((i, idx) => (
                      <div onClick={() => setBrandName(i)} className={`btn my-contents-keyword-contents ${i == brandName ? "active" : ""}`} key={idx}>
                        {i}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className="row">
                {tab == 1 &&
                  contentsRecommendation.map((i, idx) => (
                    <div key={idx} className="col-lg-4 common-contents-card-box common-contents-card-box-margin">
                      <div className={`card common-contents-card common-contents-card-${cardColor[i.studentGroupName]}`}>
                        <div className="title-view">{i.brandName}</div>
                        <div className="body-view">
                          <>
                            <p className="category">{`${i.categoryDepth1 || ""} ${i.categoryDepth2 || ""} ${i.categoryDepth3 || ""}`}</p>
                            <p className="title">
                              {i.title} ({(i.categoryDepth2 || "") + "/" + i.teacherName})
                            </p>
                          </>
                          <div>
                            <p className="percent">{i.discountRate}%</p>
                            <p className="sales-amt">{(i.partnerPrice || 0).toLocaleString()}원</p>
                            <p className="default-amt">{(i.letmeupPrice || 0).toLocaleString()} 원</p>
                            <img
                              className="btn"
                              onClick={async () => {
                                await postContentsMy({ contentSeq: i.contentSeq })
                                if (window.ReactNativeWebView) {
                                  dispatch(setBrainwavePopup({ open: true, data: { seq: i.contentSeq, url: i.homepageUrl } }))
                                } else {
                                  window.open(i.homepageUrl, "_blank")
                                }
                              }}
                              src={img_common_contents_card_right_orange}
                              alt="right img"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {tab == 0 && (
                <div className="my-buddy-wrapper my-message-wrapper">
                  <div className="list-header-view">
                    <button className="btn check-button" onClick={() => onClickAllCheckBox(contents, "contentReadSeq")}>
                      <img
                        src={checkList.length === contents.length && contents.length !== 0 ? img_checkbox_active : img_checkbox}
                        alt="checkbox img"
                      />
                      <p>전체 선택</p>
                    </button>
                    <button onClick={onClickDeleteList} className={`btn delete-button ${checkList.length > 0 ? "del-active-btn" : ""}`}>
                      삭제
                    </button>
                  </div>

                  <div className="list-body-view">
                    {contents.map((i, idx) => (
                      <div
                        key={idx}
                        // className={`my-contents-list-item color-${cardColor[i.studentsGroupName]} ${
                        className={`my-contents-list-item color-1 ${cardColor[i.studentsGroupName]} ${
                          checkList.findIndex(item => item === i.contentReadSeq) === -1 ? "" : "active"
                        }`}
                        onClick={() => isMobile && onClickCheckBox(i.contentReadSeq)}
                      >
                        <div className={`contents-list-item-label label-color-1 ${cardColor[i.studentsGroup]}`}>
                          <img
                            onClick={() => onClickCheckBox(i.contentReadSeq)}
                            src={checkList.findIndex(item => item == i.contentReadSeq) == -1 ? contents_checkbox_off : img_checkbox_active_black}
                            alt="checkbox img"
                          />
                        </div>
                        <div className={`contents-list-item-div`}>
                          <div className={`contents-list-item-info`}>
                            <p className="contents-list-item-info-title-p">{i.brandName}</p>
                            <p className="contents-list-item-info-sub-title-p">{`${i.categoryDepth1 || ""} ${i.categoryDepth2 || ""} ${
                              i.categoryDepth3 || ""
                            }`}</p>
                            <p
                              onClick={() => {
                                if (window.ReactNativeWebView) {
                                  dispatch(setBrainwavePopup({ open: true, data: { seq: i.contentSeq, url: i.homepageUrl } }))
                                } else {
                                  window.open(i.homepageUrl, "_blank")
                                }
                              }}
                              className="contents-list-item-info-p"
                            >
                              {" "}
                              {i.title} ({(i.categoryDepth2 || "") + "/" + i.teacherName})
                            </p>
                            <div className="contents-list-item-info-amt-div">
                              <p>{i.discountRate}%</p>
                              <p>{(i.partnerPrice || 0).toLocaleString()}원</p>
                              <p>{(i.letmeupPrice || 0).toLocaleString()}원</p>
                            </div>
                          </div>
                          <div className={`contents-list-item-date border-color-1 ${cardColor[i.studentsGroup]}`}>
                            <p>{i.regDatetime.split(" ")[0] || ""}</p>
                            <p>{i.regDatetime.split(" ")[1] || ""}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="my-dashboard-div-4">
                      <Desktop>
                        <img src={temp_banner} alt="banner img" />
                      </Desktop>
                      <Mobile>
                        <img src={temp_m_banner} alt="banner img" />
                      </Mobile>
                    </div>
                  </div>
                </div>
              )}
              {COUNT * (page + 1) < (tab == 0 ? contentsTotal : contentsRecommendationTotal) && (
                <div className="d-flex justify-content-center mt-4">
                  <button style={{ width: "184px" }} className={`btn btn-primary font-white more-button`} onClick={increasePage}>
                    더보기
                  </button>
                </div>
              )}
            </div>
          </div>
          <RightSideBar />
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default MyContainer(UserContainer(Contents))
